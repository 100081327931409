import withAppProviders from "app/AppProvider/withAppProvider";
import Routes from "app/routes";

const Application = withAppProviders(Routes);

function App() {
  return <Application />;
}

export default App;
