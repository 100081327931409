import { SVGprop } from "./types";

function AttentionShape({ size }: SVGprop) {
  return (
    <svg
      width={size || "77"}
      height={size || "77"}
      viewBox="0 0 77 77"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 20C39.1046 20 40 20.8954 40 22V38.1667C40 39.2713 39.1046 40.1667 38 40.1667C36.8954 40.1667 36 39.2713 36 38.1667V22C36 20.8954 36.8954 20 38 20Z"
        fill="#FF8389"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.722458 38.5C0.722458 17.3304 17.6705 0.125 38.6311 0.125C59.5918 0.125 76.5397 17.3304 76.5397 38.5C76.5397 59.6696 59.5918 76.875 38.6311 76.875C17.6705 76.875 0.722458 59.6696 0.722458 38.5ZM38.6311 4.125C19.9282 4.125 4.72246 19.4908 4.72246 38.5C4.72246 57.5092 19.9282 72.875 38.6311 72.875C57.3341 72.875 72.5397 57.5092 72.5397 38.5C72.5397 19.4908 57.3341 4.125 38.6311 4.125Z"
        fill="#FF8389"
      />
      <path
        d="M38.0001 57C39.6567 57 41 55.6565 41 53.9999C41 52.3433 39.6567 51 38.0001 51C36.3435 51 35 52.3433 35 53.9999C35 55.6565 36.3435 57 38.0001 57Z"
        fill="#FF8389"
      />
    </svg>
  );
}

export default AttentionShape;
