import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    route: urls.business,
    element: import("app/modules/business"),
  },
];

export default routes;
