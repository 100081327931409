import React from "react";
import { Route } from "react-router-dom";
import AuthRoles from "app/modules/auth/AuthRoles";
import { useAppDispatch, useCurrentRoute, useAuth } from "hooks";
import { changeLayout } from "state/ui/slice";
import { ConfiguredRoutes } from "./types";

export default function createRouteWithAuthRole({
  role,
  children,
}: ConfiguredRoutes) {
  return (
    <Route key={role} element={<AuthRoles role={role} />}>
      {children.map(({ Element, route, guard, layout }) => {
        const { loggedIn } = useAuth();
        const dispatch = useAppDispatch();
        const currentRoute = useCurrentRoute(route);
        React.useEffect(() => {
          if (layout && currentRoute) {
            dispatch(changeLayout(loggedIn ? layout.user : layout.visitor));
          }
        }, [dispatch, layout, currentRoute, loggedIn]);
        return guard ? (
          <Route
            key={`${role}-${route}`}
            path={route}
            element={loggedIn ? <Element.User /> : <Element.Visitor />}
          />
        ) : null;
      })}
    </Route>
  );
}
