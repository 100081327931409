import { SVGprop } from "./types";

const calculateProperty = (size: number, coefficient: number) =>
  size * coefficient;

function LogoShapeRed({ size }: SVGprop) {
  const heightCoefficient = 0.936;
  const height = size ? calculateProperty(size, heightCoefficient) : 117;

  return (
    <svg
      width={size || "100"}
      height={height}
      viewBox="0 0 100 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.0731 30.8628L84.1395 79.5381C80.8299 90.3549 69.206 96.5705 58.3085 93.2609L48.0568 90.1127C39.1774 87.3682 33.3654 79.1346 33.4461 70.1744C35.7871 70.013 38.128 69.4479 40.3882 68.2371L53.1423 61.4564C61.2952 57.0974 64.4434 47.0072 60.0844 38.8543L48.7833 17.5437L49.3484 15.6064C52.658 4.7089 64.2819 -1.42597 75.1794 1.88363L85.4311 5.03178C96.2478 8.4221 102.463 19.9653 99.0731 30.8628Z"
        fill="#FFEBEB"
      />
      <path
        opacity="0.88"
        d="M53.2225 61.5373L40.4685 68.3179C38.289 69.5287 35.8673 70.0938 33.5264 70.2552C33.5264 68.3179 33.8493 66.2999 34.4143 64.3625L48.7021 17.6245L60.0032 38.9351C64.4429 47.088 61.3754 57.1783 53.2225 61.5373Z"
        fill="#E09398"
      />
      <path
        opacity="0.79"
        d="M48.7832 17.5438L34.4954 64.2818C33.9303 66.2191 33.6074 68.2372 33.6074 70.1745C27.3111 70.4974 21.0148 67.2685 17.8667 61.2951L1.96443 31.3472C-2.39455 23.1943 0.753604 13.1041 8.90652 8.74508L21.6606 1.96443C29.8135 -2.39455 39.9845 0.753607 44.2627 8.90653L48.7832 17.5438Z"
        fill="#F54C53"
      />
    </svg>
  );
}

export default LogoShapeRed;
