import DeliveryIcon from "./DeliveryIcon";
import AddUser from "./AddUser";
import Apple from "./Apple";
import ExchangeShape from "./ExchangeShape";
import MailShape from "./MailShape";
import LanguageRTLIcon from "./LanguageRTLIcon";
import LanguageLTRIcon from "./LanguageLTRIcon";
import Times from "./Times";
import AttentionShape from "./AttentionShape";
import SuccessShape from "./SuccessShape";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import ArrowTop from "./ArrowTop";
import ArrowBottom from "./ArrowBottom";
import ChevronDown from "./ChevronDown";
import ChevronUp from "./ChevronUp";
import ChevronRight from "./ChevronRight";
import ChevronLeft from "./ChevronLeft";
import Minus from "./Minus";
import Search from "./Search";
import Plus from "./Plus";
import CalendarIcon from "./CalendarIcon";
import CheckIcon from "./CheckIcon";
import Linkedin from "./Linkedin";
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Twitter from "./Twitter";
import Menu from "./Menu";
import WarningIcon from "./WarningIcon";
import PlusCircleIcon from "./PlusCircleIcon";
import Info from "./Info";
import LocationIcon from "./LocationIcon";
import GreyLogoShape from "./GreyLogoShape";
import Logout from "./Logout";
import BankIcon from "./BankIcon";
import MinusCircleIcon from "./MinusCircleIcon";
import Profile from "./Profile";
import CompletedShape from "./CompletedShape";
import ReviewShape from "./ReviewShape";
import ShippingShape from "./ShippingShape";
import SubmittingShape from "./SubmittingShape";
import FavoriteChart from "./FavoriteChart";
import InfoShape from "./InfoShape";
import Edit from "./Edit";
import OrdersIcon from "./OrdersIcon";
import YoutubeIcon from "./YoutubeIcon";
import User from "./User";
import Business from "./Business";
import AttachmentIcon from "./AttachmentIcon";
import RemoveIcon from "./RemoveIcon";
import Email from "./Email";
import SBCLogo from "./SBCLogo";

export { default as LogoShapeWhite } from "./LogoShapeWhite";
export { default as LogoShapeRed } from "./LogoShapeRed";
export { default as LogoShapePurple } from "./LogoShapePurple";
export { default as LogoShapeColored } from "./LogoShapeColored";
export { default as LogoWhite } from "./LogoWhite";
export { default as LogoColored } from "./LogoColored";

const icons = {
  delivery: DeliveryIcon,
  "add-user": AddUser,
  apple: Apple,
  "exchange-shape": ExchangeShape,
  "mail-shape": MailShape,
  "language-rtl": LanguageRTLIcon,
  "language-ltr": LanguageLTRIcon,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-top": ArrowTop,
  "arrow-bottom": ArrowBottom,
  "chevron-down": ChevronDown,
  "chevron-up": ChevronUp,
  "chevron-right": ChevronRight,
  "chevron-left": ChevronLeft,
  times: Times,
  "attention-shape": AttentionShape,
  "success-shape": SuccessShape,
  "grey-logo-shape": GreyLogoShape,
  minus: Minus,
  plus: Plus,
  check: CheckIcon,
  linkedin: Linkedin,
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  menu: Menu,
  info: Info,
  calendar: CalendarIcon,
  location: LocationIcon,
  "plus-circle": PlusCircleIcon,
  "minus-circle": MinusCircleIcon,
  logout: Logout,
  bank: BankIcon,
  search: Search,
  profile: Profile,
  "complete-shape": CompletedShape,
  "review-shape": ReviewShape,
  "shipping-shape": ShippingShape,
  "submitting-shape": SubmittingShape,
  completed: CompletedShape,
  "store-review": ReviewShape,
  shipping: ShippingShape,
  submitted: SubmittingShape,
  "favorite-chart": FavoriteChart,
  edit: Edit,
  orders: OrdersIcon,
  youtube: YoutubeIcon,
  "info-shape": InfoShape,
  user: User,
  business: Business,
  remove: RemoveIcon,
  attachment: AttachmentIcon,
  email: Email,
  warning: WarningIcon,
  sbc: SBCLogo,
};

export default icons;
