import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    layout: "normal",
    route: urls.faqs,
    element: import("app/modules/faqs"),
    role: "public",
  },
];

export default routes;
