import React from "react";
import Input from "react-otp-input";
import { useFormContext } from "react-hook-form";
import { Wrapper } from "./styles";

type VerificationInputPropsType = {
  verificationCodeLength: number;
  name: string;
  hasError: boolean;
  onFinish?: (code: string) => void;
};

export default function OTPInput({
  name,
  verificationCodeLength,
  onFinish,
  hasError,
}: VerificationInputPropsType) {
  const { setValue } = useFormContext();
  const [otpValue, setOtpValue] = React.useState("");

  const handleCodeChanges = React.useCallback((value: number) => {
    setOtpValue(`${value}`);
  }, []);

  React.useEffect(() => {
    if (otpValue.length === verificationCodeLength) {
      setValue(name, otpValue);
      if (onFinish) onFinish(otpValue);
    }
  }, [name, onFinish, otpValue, setValue, verificationCodeLength]);

  return (
    <Wrapper>
      <Input
        value={otpValue}
        inputStyle="otp-input"
        containerStyle="otp-container"
        focusStyle="otp-input-focus"
        errorStyle="otp-input-error"
        numInputs={verificationCodeLength}
        isInputNum
        onChange={handleCodeChanges}
        shouldAutoFocus
        hasErrored={hasError}
      />
    </Wrapper>
  );
}
