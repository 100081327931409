import "./firebase";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);

// ReactGA.initialize(process.env.GOOGLE_MEASUREMENT_ID as string);
// const SendAnalytics = () => {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// };

// reportWebVitals(SendAnalytics);
