import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function Apple({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3 21.3C8.6 21.3 7.8 20.9 7 20.1C5.4 18.6 3.8 15.6 3.8 12.7C3.8 8.9 6.6 6.8 9.1 6.8C9.9 6.7 10.7 7 11.3 7.3C11.6 7.4 11.9 7.5 12.1 7.5C12.2 7.5 12.6 7.4 12.9 7.2C13.6 6.9 14.6 6.5 15.6 6.7C16.1 6.7 18 6.8 19.4 8.7C19.5 8.9 19.6 9.1 19.5 9.3C19.5 9.5 19.3 9.7 19.2 9.8C18.2 10.4 17.7 11.4 17.7 12.4C17.8 13.6 18.5 14.7 19.8 15.3C20.1 15.5 20.3 15.9 20.2 16.3C19.5 18 17.6 21.2 15.3 21.2C14.5 21.2 14 21 13.6 20.8C13.2 20.6 12.9 20.5 12.3 20.5C11.7 20.5 11.4 20.6 11 20.8C10.6 21 10.1 21.3 9.3 21.3ZM9.3 8.4H9.2C7.6 8.4 5.3 9.6 5.3 12.8C5.3 15.2 6.7 17.8 8 19.1C8.8 19.8 9.2 19.9 9.4 19.9C9.9 19.9 10.1 19.8 10.5 19.6C10.9 19.4 11.5 19.2 12.4 19.2C13.3 19.2 13.8 19.4 14.3 19.6C14.7 19.8 15 19.9 15.4 19.9C16.5 19.9 17.9 18.1 18.7 16.5C17.3 15.6 16.5 14.2 16.4 12.7C16.3 11.4 16.9 10.2 17.9 9.3C17 8.5 16 8.5 15.7 8.5C14.9 8.4 14.2 8.7 13.6 8.9C13.1 9.1 12.7 9.3 12.2 9.3C11.7 9.3 11.3 9.1 10.8 9C10.3 8.5 9.8 8.4 9.3 8.4Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M11.8 6.2C11.7 6.2 11.6 6.2 11.5 6.2C11.2 6.2 10.9 5.9 10.8 5.6C10.7 4.7 11 3.7 11.7 2.8C12.7 1.6 14.3 0.800003 16 0.800003C16.4 0.800003 16.7 1.1 16.8 1.4C16.9 2.5 16.5 3.6 15.7 4.6C14.6 5.6 13.2 6.2 11.8 6.2ZM15.2 2.4C14.3 2.6 13.4 3.1 12.9 3.8C12.6 4.1 12.5 4.4 12.4 4.8C13.2 4.7 14 4.3 14.6 3.6C14.8 3.2 15.1 2.8 15.2 2.4Z"
        fill={color || theme.pallet.primary[500]}
      />
    </svg>
  );
}

export default Apple;
