import i18n from "locales/i18n";

i18n.addResourceBundle("en", "app", {
  learnMore: "Want to learn more ? ",
  storeUrl: "Store url",
  weWillContactYou: "Fill in your information",
  requestSent: "Your request has been sent",
  joinUsSubmit: "submit",
  applyNow: "Apply now",
  newAnnualRevenue: "New annual revenue with instant refunds",
  newMonthlyRevenue: "New monthly revenue with instant refunds",
  newMonthlyRepurchase: "New Monthly repurchase with instant refunds",
  averageOrderValue: "Average Order Value",
  refundRate: "Refund Rate",
  monthlySalesVolume: "Monthly Sales Volume ",
  sar: "SAR",
  calculateYourRevenue: "Calculate Your Revenue",
  AddNewRevenue: "Add New Revenue",
});
