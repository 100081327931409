import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function CompletedShape({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "40"}
      height={size || "40"}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4972 10.9579C32.8886 11.3475 32.89 11.9807 32.5003 12.3721L20.8841 24.0387C20.6926 24.2311 20.4312 24.3373 20.1598 24.333C19.8884 24.3288 19.6304 24.2144 19.445 24.0161L14.3945 18.6136C14.0173 18.2101 14.0386 17.5773 14.4421 17.2002C14.8455 16.823 15.4783 16.8443 15.8555 17.2478L20.1982 21.8931L31.083 10.9609C31.4727 10.5696 32.1059 10.5682 32.4972 10.9579Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.125 6C12.393 6 6.125 12.268 6.125 20C6.125 27.732 12.393 34 20.125 34C27.857 34 34.125 27.732 34.125 20C34.125 19.4477 34.5727 19 35.125 19C35.6773 19 36.125 19.4477 36.125 20C36.125 28.8366 28.9616 36 20.125 36C11.2885 36 4.125 28.8366 4.125 20C4.125 11.1634 11.2884 4 20.125 4C21.8694 4 23.5504 4.27951 25.1247 4.79697C25.6494 4.96942 25.9349 5.53454 25.7625 6.05921C25.5901 6.58389 25.0249 6.86942 24.5003 6.69697C23.1249 6.24493 21.6546 6 20.125 6Z"
        fill={color || theme.pallet.primary[500]}
      />
    </svg>
  );
}

export default CompletedShape;
