import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function Instagram({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25_20)">
        <path
          d="M1.50061 1.63352C-0.385389 3.59252 0.000611241 5.67352 0.000611241 11.9955C0.000611241 17.2455 -0.915389 22.5085 3.87861 23.7475C5.37561 24.1325 18.6396 24.1325 20.1346 23.7455C22.1306 23.2305 23.7546 21.6115 23.9766 18.7885C24.0076 18.3945 24.0076 5.60352 23.9756 5.20152C23.7396 2.19452 21.8886 0.461516 19.4496 0.110516C18.8906 0.0295156 18.7786 0.0055156 15.9106 0.000515603C5.73761 0.0055156 3.50761 -0.447484 1.50061 1.63352V1.63352Z"
          fill={color || theme.pallet.primary[800]}
        />
        <path
          d="M11.998 3.13836C8.36696 3.13836 4.91896 2.81536 3.60196 6.19536C3.05796 7.59136 3.13696 9.40436 3.13696 12.0004C3.13696 14.2784 3.06396 16.4194 3.60196 17.8044C4.91596 21.1864 8.39196 20.8624 11.996 20.8624C15.473 20.8624 19.058 21.2244 20.391 17.8044C20.936 16.3944 20.856 14.6084 20.856 12.0004C20.856 8.53836 21.047 6.30336 19.368 4.62536C17.668 2.92536 15.369 3.13836 11.994 3.13836H11.998ZM11.204 4.73536C18.778 4.72336 19.742 3.88136 19.21 15.5784C19.021 19.7154 15.871 19.2614 11.999 19.2614C4.93896 19.2614 4.73596 19.0594 4.73596 11.9964C4.73596 4.85136 5.29596 4.73936 11.204 4.73336V4.73536ZM16.728 6.20636C16.141 6.20636 15.665 6.68236 15.665 7.26936C15.665 7.85636 16.141 8.33236 16.728 8.33236C17.315 8.33236 17.791 7.85636 17.791 7.26936C17.791 6.68236 17.315 6.20636 16.728 6.20636V6.20636ZM11.998 7.44936C9.48496 7.44936 7.44796 9.48736 7.44796 12.0004C7.44796 14.5134 9.48496 16.5504 11.998 16.5504C14.511 16.5504 16.547 14.5134 16.547 12.0004C16.547 9.48736 14.511 7.44936 11.998 7.44936V7.44936ZM11.998 9.04636C15.903 9.04636 15.908 14.9544 11.998 14.9544C8.09396 14.9544 8.08796 9.04636 11.998 9.04636Z"
          fill={theme.colors.shades[100]}
        />
      </g>
      <defs>
        <clipPath id="clip0_25_20">
          <rect width="24" height="24" fill={theme.colors.shades[100]} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Instagram;
