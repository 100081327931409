import { css } from "@emotion/react";
import { Flex } from "components/Grids";
import styled from "@emotion/styled";

const SocialButton = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  ${({ theme }) => css`
    background: ${theme.colors.shades[100]};
  `}
`;

export default SocialButton;
