import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    layout: "small-footer-no-header",
    route: "/create-order",
    element: import("app/modules/orders/CreateOrderPage"),
  },
  {
    layout: "small-footer-no-header",
    route: "/orders/successfully-created",
    element: import("app/modules/orders/SuccessStatusPage"),
    role: "consumer",
  },
  {
    layout: "small-footer-no-header",
    route: "/orders/failed-order",
    element: import("app/modules/orders/FailedToCompletePage"),
    role: "consumer",
  },
  {
    layout: "no-footer",
    route: "/orders/:orderId",
    element: import("app/modules/orders/OrderPage"),
    role: "consumer",
  },
  {
    layout: "small-footer",
    route: urls.orders,
    element: import("app/modules/orders/OrdersPage"),
    role: "consumer",
  },
];

export default routes;
