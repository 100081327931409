import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    route: urls.account,
    element: import("app/modules/account"),
  },
];

export default routes;
