import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function SubmittingIcon({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "32"}
      height={size || "32"}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6667 3.3335C18.219 3.3335 18.6667 3.78121 18.6667 4.3335V13.3335H27.6667C28.219 13.3335 28.6667 13.7812 28.6667 14.3335C28.6667 14.8858 28.219 15.3335 27.6667 15.3335H17.6667C17.1145 15.3335 16.6667 14.8858 16.6667 14.3335V4.3335C16.6667 3.78121 17.1145 3.3335 17.6667 3.3335Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0405 1.9594C30.431 2.34992 30.431 2.98309 30.0405 3.37361L18.3739 15.0403C17.9833 15.4308 17.3502 15.4308 16.9596 15.0403C16.5691 14.6498 16.5691 14.0166 16.9596 13.6261L28.6263 1.9594C29.0168 1.56887 29.65 1.56887 30.0405 1.9594Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39642 8.88825C2.10439 10.7634 2 13.0973 2 16C2 22.6942 2.86668 26.0046 4.79767 27.7601C5.75802 28.6331 7.07042 29.2034 8.91787 29.5482C10.7748 29.8948 13.0928 30 16 30C18.9024 30 21.2372 29.8956 23.1134 29.6067C24.9918 29.3174 26.3366 28.8534 27.3091 28.1859C29.1636 26.9131 30 24.6501 30 20.0833C30 19.531 30.4477 19.0833 31 19.0833C31.5523 19.0833 32 19.531 32 20.0833C32 24.7666 31.1697 27.9619 28.4409 29.8349C27.1217 30.7403 25.4457 31.2711 23.4178 31.5834C21.3878 31.896 18.9309 32 16 32C13.0739 32 10.6002 31.8968 8.55088 31.5143C6.49208 31.13 4.78365 30.4502 3.45233 29.2399C0.799984 26.8287 0 22.6391 0 16C0 13.0693 0.103941 10.6116 0.420244 8.5805C0.736287 6.55111 1.27484 4.87296 2.19674 3.55254C4.09956 0.827134 7.34874 0 12.1333 0C12.6856 0 13.1333 0.447715 13.1333 1C13.1333 1.55228 12.6856 2 12.1333 2C7.45127 2 5.13377 2.83953 3.8366 4.69746C3.15849 5.66871 2.68871 7.01139 2.39642 8.88825Z"
        fill={color || theme.pallet.primary[500]}
      />
    </svg>
  );
}

export default SubmittingIcon;
