import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    layout: "empty",
    route: urls.login,
    element: import("./pages/LoginPage"),
  },
  {
    layout: "empty",
    route: urls.verifyNafath,
    element: import("./pages/NafathValidatePage"),
  },
  {
    layout: "empty",
    route: urls.loginSuccess,
    element: import("./pages/SuccessStatusPage"),
  },
  {
    layout: "empty",
    route: urls.loginFailed,
    element: import("./pages/FailedStatusPage"),
  },
];

export default routes;
