import i18n from "locales/i18n";

i18n.addResourceBundle("en", "app", {
  welcome: "Welcome to yammpay",
  loading: "Loading",
  requiredField: "This field is required",
  goBack: "go back",
  signUp: "sign up",
  signUpNow: "Sign up now to start receiving instant refunds!",
  loginNow: "login to Yamm account",
  changeLanguage: "Change language",
  about: "About us",
  login: "Login",
  copyrights: "Copyright Yamm© {{year}}. All right reserved",
  receivePromotion: "Receive news and promotions",
  email: "Email Address",
  invalidEmail: "Invalid email address",
  stayUpdated: "Stay Updated",
  helpCenter: "Help Center",
  privacyPolicy: "Privacy Policy",
  home: "Home",
  terms: "Terms & Conditions",
  enterPhoneNumber: "Enter phone number",
  phoneNumber: "phone number",
  dialCode: "Country Code",
  rememberMe: "remember me",
  dontHaveAccount: "Don't have account ?",
  otpSentToYou:
    "You have to enter an OTP code we sent via SMS to your phone number {{number}}",
  expiredOTP: "This OTP code has been expired",
  loginSuccess: "login success",
  loginFailed: "login failed",
  nationalId: "nationality ID",
  dateOfBirth: "date of birth",
  successBody: "Now you can enjoy and use all of best features on Yamm",
  failedBody:
    "Some of the credentials you used are invalid please make sure the all provided information are correct and belongs to you",
  registerSuccess: "register success",
  registerFailed: "register failed",
  nextStep: "next step",
  personalInfo: "personal information",
  resendCode: "resend code",
  otpError: "Otp Error",
  returnToOrders: "return to orders",
  searchLocation: "search location",
  logout: "logout",
  sureLogout: "are you sure you want to logout ?",
  profileDetails: "Profile Details",
  myOrders: "My Orders",
  nationalIdMinLength: "national ID number must not be less than 10",
  phoneMinLength: "Phone number must not be less than 9",
  phoneMaxLength: "Phone number must not be more than 9",
  goHome: "go home",
  searchIdOrStoreName: "Search id or store name...",
  partiallyRejected: "partially rejected",
  confirmNotificationMessage:
    "We need you confirmation to allow the push notifications, in order to be able to track your order status",
  registerSubmit: "confirm",
  startWithZeroPhoneNumber: "phone number should not start with zero",
  nationalIdRequired: "national ID required",
  accept: "Accept",
  iAgree: "I agree with",
  userAgreement: "I agree with all of Digital Legal Acceptance ",
  ibanHelper: "Example: SA99 9999 9999 9999 9999 9999",
  inProgress: "in progress",
  waitingToBeShipped: "waiting to be shipped",
  pendingInvoiceCustome: "بإنتظار معالجة فاتورة العميل",
  inReview: "in review",
  rejectedItems: "rejected items",
  onTheWay: "on the way",
  yammBusiness: "for business",
  phoneNumberPlaceholder: "5xxxxxxxx",
  faqs: "FAQs",
  readMore: "Read More",
  badConnection: "You have an unstable internet connection.",
  nafathConfirmationNeeded: "Nafath confirmation needed",
  openNafathApplication: "Open Nafath application in your mobile phone",
  enterTheBelowCodeToNafathAuthenticator:
    "Open Nafath app and enter the below code to Nafath authenticator",
  resendNafathVerification: "resend Nafath Verification",
  ifNafathVerified:
    "In case you verified your credentials through Nafath you will be redirected to the home screen, if you did not get redirected to the home screen",
  navigateNafathSuccess: "click here",
  phoneNumberRequired: "phone number required",
  titleTab:
    "Yamm - يمّ | Retrieve the value of your purchases from online stores.",
  metaDescription:
    "An e-commerce refund system; reclaim the value of your purchases from online stores within 30 seconds.",
  verifyNafathManually:
    "In case you are not redirected automatically, click here",
  clientLoginButton: "Clients",
  businessLoginButton: "Merchants",
  requireNafath:
    "Registering to Yamm requires Nafath the official application of the National information center, this in order to securely confirming your identity",
  requireNafathGuild:
    "Please open Nafath application which is linked to the identity {{nationalId}} and confirm the request by choosing the number displayed on yamm website",
  clickHere: "click here",
  increaseRevenue: "Increase Revenue",
  updateNationalId: "update national ID",
  nationalIdUpdatedSuccessfully: "National ID updated successfully",
  checkIban: "check iban",
  ifYouAreFacingIssues: "if you are facing issues with registering",
  abortProcess: "click here to abort the process and try again later",
  whyNafathQuestion: "Why do we need to check your identity through Nafath?",
  whyNafathAnswer:
    "In order to adhere to our AML (Anti Money Laundering) regulations, Refundid requires customers to verify their details through Nafath. Your personal information is safe and will only be seen by authorized persons and entities.",
  needHelp: "Need Help? contact us",
  contactUs: "Contact Us",
  unknownError: "There is a technical error, please try again later",
  nafathVerificationMayDelay:
    "Nafath verification may take some time, please be patient",
  gotStuck: "got stuck ?",
  newUserFirstName: "New",
  newUserLastName: "User",
});
