import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function Twitter({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 5.12307C22.191 5.47207 21.3105 5.72324 20.4037 5.82107C21.3452 5.26171 22.0503 4.37802 22.3866 3.33579C21.5031 3.86137 20.5352 4.22992 19.5259 4.42508C19.104 3.9741 18.5938 3.61483 18.0271 3.36965C17.4603 3.12447 16.8491 2.99865 16.2316 3.00001C13.7331 3.00001 11.7237 5.02525 11.7237 7.51053C11.7237 7.85952 11.766 8.20852 11.8348 8.5443C8.09362 8.34865 4.757 6.56136 2.53876 3.82491C2.13457 4.51528 1.92276 5.30134 1.92537 6.10132C1.92537 7.66652 2.72119 9.04664 3.93474 9.85832C3.21958 9.83016 2.52116 9.63358 1.89629 9.28459V9.34011C1.89629 11.5319 3.44562 13.3483 5.51052 13.766C5.12281 13.8667 4.72397 13.9183 4.3234 13.9194C4.02992 13.9194 3.75231 13.8903 3.47206 13.8506C4.04314 15.6379 5.70616 16.9361 7.68646 16.9784C6.13712 18.1919 4.19649 18.9058 2.08929 18.9058C1.71121 18.9058 1.36222 18.8926 1 18.8503C2.9988 20.1326 5.37039 20.8729 7.92441 20.8729C16.2157 20.8729 20.7527 14.004 20.7527 8.04195C20.7527 7.8463 20.7527 7.65065 20.7395 7.455C21.6172 6.81253 22.3866 6.01672 23 5.12307Z"
        fill={color || theme.pallet.primary[800]}
      />
    </svg>
  );
}

export default Twitter;
