// public routes
import homeRoutes from "app/modules/home/routes";
import aboutRoutes from "app/modules/about/routes";
import notFoundRoutes from "app/modules/not-found/routes";
import accessDeniedRoutes from "app/modules/access-denied/routes";
import ordersRoutes from "app/modules/orders/routes";
import maintenanceRoutes from "app/modules/maintenance/routes";
import serverErrorRoutes from "app/modules/server-error/routes";
import loginRouters from "app/modules/auth/routes";
import accountRoutes from "app/modules/account/routes";
import businessRoutes from "app/modules/business/routes";
import TermsAndConditions from "app/modules/terms-and-conditions/routes";
import Faqs from "app/modules/faqs/routes";
import StoreRoutes from "app/modules/store/routes";
import Privacy from "app/modules/privacy-policy/routes";

import resolveRoutingConfig from "./resolveRoutingConfig";
import { RouteType } from "./types";

const routes: RouteType[] = [
  ...homeRoutes,
  ...aboutRoutes,
  ...notFoundRoutes,
  ...accessDeniedRoutes,
  ...ordersRoutes,
  ...maintenanceRoutes,
  ...serverErrorRoutes,
  ...loginRouters,
  ...accountRoutes,
  ...businessRoutes,
  ...TermsAndConditions,
  ...Faqs,
  ...StoreRoutes,
  ...Privacy,
];

export default resolveRoutingConfig(routes, []);
