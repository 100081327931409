import { RouteType } from "app/routes/types";

const routes: RouteType[] = [
  {
    layout: "normal",
    route: "/",
    element: import("app/modules/home/pages/Visitor"),
    role: "public",
  },
  {
    layout: "no-footer",
    route: "/home/error",
    element: import("app/modules/home/error"),
    role: "public",
  },
];

export default routes;
