import { RouteType } from "app/routes/types";
import urls from "helpers/urls";

const routes: RouteType[] = [
  {
    layout: "empty",
    route: urls.maintenance,
    element: import("app/modules/maintenance"),
  },
];

export default routes;
