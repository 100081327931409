import { RouteType } from "app/routes/types";

const routes: RouteType[] = [
  {
    layout: "empty",
    route: "/access-denied",
    element: import("app/modules/access-denied"),
  },
];

export default routes;
