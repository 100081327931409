import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function DeliveryIcon({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6.2H5C4.6 6.2 4.2 6.6 4.2 7C4.2 7.4 4.6 7.7 5 7.7H7C7.4 7.7 7.8 7.4 7.8 6.9C7.8 6.4 7.4 6.2 7 6.2Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M22.8 11C22.8 10.9 22.8 10.8 22.7 10.7C22.5 9.5 22 8.6 21.4 7.5L21.2 7.2C21.1 6.9 20.9 6.7 20.8 6.5C19.8 4.7 19.1 3.3 15.5 3.3H6C1.8 3.3 1.2 6.2 1.2 8.7V15.7C1.2 15.8 1.2 15.8 1.2 15.9C1.2 16 1.2 16 1.2 16.1V17.1C1.2 18 1.8 18.7 2.6 18.8C3.2 20 4.5 20.8 5.9 20.8C7.3 20.8 8.6 20 9.2 18.8H13.6C14.2 20 15.5 20.8 16.9 20.8C18.3 20.8 19.6 20 20.2 18.8H20.4C22.6 18.8 22.6 16.6 22.6 15.7V12C22.6 11.7 22.6 11.4 22.6 11.2C22.7 11.1 22.7 11.1 22.8 11ZM19.4 7.1C19.5 7.3 19.7 7.6 19.8 7.9L20 8.2C20.5 9 20.8 9.6 21 10.2C19.7 10.1 18 9.7 17.2 8.9C16.4 8 16 6 15.8 4.8C18.2 4.8 18.6 5.5 19.4 7.1ZM6 19.3C4.8 19.3 3.8 18.3 3.8 17.1C3.8 15.9 4.8 14.9 6 14.9C7.2 14.9 8.2 15.9 8.2 17.1C8.2 18.3 7.2 19.3 6 19.3ZM17 19.3C15.8 19.3 14.8 18.3 14.8 17.1C14.8 15.9 15.8 14.9 17 14.9C18.2 14.9 19.2 15.9 19.2 17.1C19.2 18.3 18.2 19.3 17 19.3ZM21.2 15.7C21.2 17 21 17.2 20.7 17.3C20.7 17.2 20.7 17.1 20.7 17.1C20.7 15 19 13.3 16.9 13.3C14.8 13.3 13.1 15 13.1 17.1C13.1 17.2 13.1 17.3 13.1 17.3H9.7C9.7 17.2 9.7 17.1 9.7 17.1C9.7 15 8 13.3 5.9 13.3C4.5 13.3 3.3 14.1 2.7 15.2V8.7C2.7 5.8 3.5 4.8 5.9 4.8H14.2C14.3 6 14.8 8.6 16 9.9C17.3 11.3 19.8 11.6 21.2 11.7C21.2 11.8 21.2 11.9 21.2 12V15.7Z"
        fill={color || theme.pallet.primary[500]}
      />
    </svg>
  );
}

export default DeliveryIcon;
