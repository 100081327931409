import * as Sentry from "@sentry/react";
import { persister, store } from "app/state";
import "locales";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import AppProvider from "./AppProvider";

type LayoutType = { children: React.ReactNode };

export default function withAppProviders<T extends {}>(
  WrappedComponent: React.ComponentType<T>,
  Layout?: React.ComponentType<LayoutType>,
) {
  function Wrapper(props: T) {
    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0,
      });
    }
    return (
      <ReduxProvider store={store}>
        <PersistGate
          loading={<span>loading persisted Data</span>}
          persistor={persister}
        >
          <React.Suspense fallback={<div>Loading...</div>}>
            <Router>
              <AppProvider>
                {Layout ? (
                  <Layout>
                    <WrappedComponent {...props} />
                  </Layout>
                ) : (
                  <WrappedComponent {...props} />
                )}
              </AppProvider>
            </Router>
          </React.Suspense>
        </PersistGate>
      </ReduxProvider>
    );
  }

  return Wrapper;
}
