import {
  EmptyLayout,
  NoFooter,
  NormalLayout,
  SmallFooter,
  SmallFooterNoHeader,
} from "app/layout";
import LoadingScreen from "components/LoadingScreen";
import { useAppSelector } from "hooks";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import createRouteWithAuthRole from "./createRouteWithAuthRole";
import routes from "./routesConfig";

const layouts = {
  normal: NormalLayout,
  empty: EmptyLayout,
  "no-footer": NoFooter,
  "small-footer": SmallFooter,
  "small-footer-no-header": SmallFooterNoHeader,
};

export default function RouterBuilder() {
  const layoutState = useAppSelector((state) => state.ui.layout);

  const Layout = React.useMemo(
    () => layouts[layoutState || "normal"],
    [layoutState],
  );

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <Layout>
        <Routes>
          {routes.map(createRouteWithAuthRole)}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Layout>
    </React.Suspense>
  );
}
