import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function OrdersIcon({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6251 4.79764C9.97908 4.75047 9.13621 4.75 8 4.75C6.86441 4.75 6.01976 4.75042 5.37191 4.7951C4.70979 4.84076 4.36452 4.92822 4.17164 5.03342C4.03718 5.10677 3.94429 5.20279 3.87108 5.46636C3.78428 5.77883 3.75 6.24775 3.75 7C3.75 7.75225 3.78428 8.22117 3.87108 8.53364C3.94429 8.79721 4.03718 8.89323 4.17164 8.96658C4.36452 9.07178 4.70979 9.15924 5.37191 9.2049C6.01976 9.24958 6.86441 9.25 8 9.25C9.13497 9.25 9.9814 9.24963 10.6311 9.20746C11.2954 9.16434 11.6471 9.08142 11.845 8.97984C11.9823 8.90935 12.0651 8.82413 12.1322 8.59652C12.2154 8.31475 12.25 7.88446 12.25 7.17647C12.25 6.38001 12.216 5.87304 12.1261 5.53039C12.0477 5.23136 11.9455 5.12339 11.8124 5.04652C11.624 4.9377 11.2848 4.84582 10.6251 4.79764ZM13.577 5.14975C13.7215 5.70049 13.75 6.3847 13.75 7.17647C13.75 7.88025 13.7221 8.50877 13.5709 9.02112C13.4037 9.58764 13.0802 10.0318 12.53 10.3143C12.0404 10.5656 11.4233 10.6592 10.7283 10.7043C10.0242 10.75 9.12936 10.75 8.02651 10.75H7.97195C6.87039 10.75 5.97426 10.75 5.26871 10.7013C4.57146 10.6533 3.94798 10.5532 3.45336 10.2834C2.90032 9.98177 2.58696 9.51529 2.4258 8.93511C2.27822 8.40383 2.25 7.74775 2.25 7C2.25 6.25225 2.27822 5.59617 2.4258 5.06489C2.58696 4.48471 2.90032 4.01823 3.45336 3.71658C3.94798 3.44678 4.57146 3.34674 5.26871 3.29865C5.97426 3.24999 6.8704 3.25 7.97196 3.25L8.02955 3.25C9.12985 3.25 10.0272 3.24999 10.7343 3.30162C11.4339 3.35271 12.0635 3.45936 12.5626 3.74759C13.117 4.06779 13.4211 4.55541 13.577 5.14975Z"
        fill={color || theme.colors.dark[200]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8678 15.4035C11.7846 15.6852 11.75 16.1155 11.75 16.8235C11.75 17.62 11.784 18.1269 11.8739 18.4696C11.9523 18.7686 12.0545 18.8766 12.1876 18.9535C12.376 19.0623 12.7152 19.1542 13.3749 19.2024C14.0209 19.2495 14.8638 19.25 16 19.25C17.1356 19.25 17.9802 19.2496 18.6281 19.2049C19.2902 19.1592 19.6355 19.0718 19.8284 18.9666C19.9628 18.8932 20.0557 18.7972 20.1289 18.5336C20.2157 18.2212 20.25 17.7523 20.25 17C20.25 16.2477 20.2157 15.7788 20.1289 15.4664C20.0557 15.2028 19.9628 15.1068 19.8284 15.0334C19.6355 14.9282 19.2902 14.8408 18.6281 14.7951C17.9802 14.7504 17.1356 14.75 16 14.75C14.865 14.75 14.0186 14.7504 13.3689 14.7925C12.7046 14.8357 12.3529 14.9186 12.155 15.0202C12.0177 15.0906 11.9349 15.1759 11.8678 15.4035ZM15.9735 13.25H16.028C17.1296 13.25 18.0257 13.25 18.7313 13.2987C19.4285 13.3467 20.052 13.4468 20.5466 13.7166C21.0997 14.0182 21.413 14.4847 21.5742 15.0649C21.7218 15.5962 21.75 16.2523 21.75 17C21.75 17.7477 21.7218 18.4038 21.5742 18.9351C21.413 19.5153 21.0997 19.9818 20.5466 20.2834C20.052 20.5532 19.4285 20.6533 18.7313 20.7013C18.0257 20.75 17.1296 20.75 16.028 20.75H15.9705C14.8702 20.75 13.9728 20.75 13.2657 20.6984C12.5661 20.6473 11.9365 20.5406 11.4374 20.2524C10.883 19.9322 10.5789 19.4446 10.423 18.8502C10.2785 18.2995 10.25 17.6153 10.25 16.8235C10.25 16.1197 10.2779 15.4912 10.4291 14.9789C10.5963 14.4123 10.9198 13.9682 11.47 13.6857C11.9596 13.4344 12.5767 13.3408 13.2717 13.2957C13.9758 13.25 14.8706 13.25 15.9735 13.25Z"
        fill={color || theme.colors.dark[200]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.915 4.78723C19.7283 4.75287 19.4645 4.75 19 4.75C18.5757 4.75 18.3268 4.75262 18.1443 4.7852C18.003 4.81043 17.9879 4.83928 17.9859 4.84313C17.9858 4.84333 17.9857 4.84346 17.9857 4.84352C17.9591 4.87672 17.8849 5.00079 17.8294 5.37554C17.7753 5.7407 17.75 6.25801 17.75 7C17.75 7.742 17.7753 8.25931 17.8294 8.62447C17.8849 8.99922 17.9591 9.12329 17.9857 9.15648C17.9857 9.15655 17.9858 9.15668 17.9859 9.15687C17.9879 9.16073 18.003 9.18957 18.1443 9.21481C18.3268 9.24739 18.5757 9.25 19 9.25C19.423 9.25 19.6763 9.24767 19.8632 9.21626C19.9466 9.20224 19.9882 9.1863 20.0066 9.17727C20.0201 9.17067 20.0238 9.16692 20.0285 9.16133C20.0468 9.13986 20.1174 9.0368 20.1716 8.69221C20.2247 8.35525 20.25 7.87381 20.25 7.17647C20.25 6.38312 20.2339 5.82519 20.1884 5.42479C20.1411 5.00908 20.0715 4.86912 20.0442 4.83058C20.0395 4.82384 20.0388 4.82353 20.0375 4.82297C20.0372 4.82285 20.0369 4.82271 20.0365 4.8225C20.0283 4.81794 19.996 4.80216 19.915 4.78723ZM21.6788 5.25536C21.7348 5.74835 21.75 6.38159 21.75 7.17647C21.75 7.8909 21.7253 8.46829 21.6534 8.92545C21.5826 9.37498 21.4532 9.80132 21.1715 10.1328C20.868 10.4898 20.4782 10.6339 20.1118 10.6955C19.7865 10.7502 19.4069 10.7501 19.0429 10.75C19.0285 10.75 19.0143 10.75 19 10.75C18.9852 10.75 18.9704 10.75 18.9555 10.75C18.5929 10.7501 18.2097 10.7502 17.8807 10.6914C17.5042 10.6242 17.1133 10.4672 16.8143 10.0935C16.5409 9.75172 16.4151 9.31329 16.3456 8.84429C16.2747 8.3657 16.25 7.75801 16.25 7C16.25 6.242 16.2747 5.6343 16.3456 5.15572C16.4151 4.68672 16.5409 4.24829 16.8143 3.90648C17.1133 3.53277 17.5042 3.37578 17.8807 3.30856C18.2097 3.24979 18.5929 3.2499 18.9555 3.25C18.9704 3.25 18.9852 3.25 19 3.25C19.0167 3.25 19.0333 3.25 19.05 3.24999C19.4447 3.24988 19.8484 3.24977 20.1866 3.31204C20.5807 3.38461 20.9802 3.55693 21.2683 3.96355C21.5222 4.32206 21.6245 4.77769 21.6788 5.25536Z"
        fill={color || theme.colors.dark[200]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85566 14.7852C5.67323 14.7526 5.42434 14.75 5 14.75C4.57702 14.75 4.32373 14.7523 4.13681 14.7837C4.0534 14.7978 4.01185 14.8137 3.99336 14.8227C3.97986 14.8293 3.97621 14.8331 3.97145 14.8387C3.95321 14.8601 3.88262 14.9632 3.82838 15.3078C3.77534 15.6447 3.75 16.1262 3.75 16.8235C3.75 17.6169 3.76609 18.1748 3.81161 18.5752C3.85887 18.9909 3.92847 19.1309 3.95577 19.1694C3.96054 19.1762 3.96125 19.1765 3.96254 19.177C3.96282 19.1772 3.96313 19.1773 3.96351 19.1775C3.97168 19.1821 4.004 19.1978 4.08504 19.2128C4.27165 19.2471 4.53546 19.25 5 19.25C5.42434 19.25 5.67323 19.2474 5.85566 19.2148C5.99697 19.1896 6.0121 19.1607 6.01413 19.1569C6.01423 19.1567 6.0143 19.1565 6.01435 19.1565C6.0409 19.1233 6.11508 18.9992 6.1706 18.6245C6.2247 18.2593 6.25 17.742 6.25 17C6.25 16.258 6.2247 15.7407 6.1706 15.3755C6.11508 15.0008 6.0409 14.8767 6.01435 14.8435C6.0143 14.8435 6.01423 14.8433 6.01413 14.8431C6.0121 14.8393 5.99697 14.8104 5.85566 14.7852ZM7.6544 15.1557C7.7253 15.6343 7.75 16.242 7.75 17C7.75 17.758 7.7253 18.3657 7.6544 18.8443C7.58492 19.3133 7.4591 19.7517 7.18565 20.0935C6.88668 20.4672 6.49579 20.6242 6.11934 20.6914C5.79025 20.7502 5.40713 20.7501 5.04445 20.75C5.0296 20.75 5.01478 20.75 5 20.75C4.98335 20.75 4.96668 20.75 4.94999 20.75C4.55528 20.7501 4.15155 20.7502 3.81339 20.688C3.41928 20.6154 3.01975 20.4431 2.73173 20.0364C2.47778 19.6779 2.37551 19.2223 2.32121 18.7446C2.26516 18.2516 2.25 17.6184 2.25 16.8235C2.25 16.1091 2.27466 15.5317 2.34662 15.0745C2.41738 14.625 2.54679 14.1987 2.82855 13.8672C3.13202 13.5102 3.52177 13.3661 3.88819 13.3045C4.21349 13.2498 4.59306 13.2499 4.95714 13.25C4.97146 13.25 4.98574 13.25 5 13.25C5.01478 13.25 5.0296 13.25 5.04445 13.25C5.40713 13.2499 5.79025 13.2498 6.11934 13.3086C6.49579 13.3758 6.88668 13.5328 7.18565 13.9065C7.4591 14.2483 7.58492 14.6867 7.6544 15.1557Z"
        fill={color || theme.colors.dark[200]}
      />
    </svg>
  );
}

export default OrdersIcon;
