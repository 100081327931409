import React from "react";
import { Beforeunload } from "react-beforeunload";
import moment from "moment";
import WhatsappLink from "components/WhatsappLink";
import { useAppSelector, useAppDispatch, useAuth } from "hooks";
import LayoutWrapper from "./styles";
import Header from "./Header";

export default function Layout({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const {
    consumerAuth: { rememberMe, token },
  } = useAppSelector((state) => state);

  const { logout } = useAuth();

  React.useEffect(() => {
    if (moment(token?.expirationDate).isBefore()) {
      logout();
    }
  }, [logout, token]);

  return (
    <Beforeunload
      onBeforeunload={() => {
        if (!rememberMe) {
          dispatch(logout());
        }
      }}
    >
      <LayoutWrapper data-testid="app-layout">
        <main className="content">
          <Header />
          {children}
        </main>
        <WhatsappLink />
      </LayoutWrapper>
    </Beforeunload>
  );
}
