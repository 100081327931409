import i18n from "locales/i18n";

i18n.addResourceBundle("en", "app", {
  BackToMyDashboard: "Back to My Dashboard",
  hi: "Hi, {{firstName}} {{lastName}}",
  personalInformation: "Personal Information",
  banks: "Bank Accounts",
  address: "Address",
  update: "Update",
});
