import urls from "helpers/urls";

const navigation = [
  {
    label: "home",
    path: urls.home,
  },
  {
    label: "about",
    path: urls.about,
  },
  // {
  //   label: "calculator",
  //   path: urls.business,
  //   hash: "#calculator",
  // },
  {
    label: "yammBusiness",
    path: urls.business,
  },
];

export default navigation;
