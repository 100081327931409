import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function CalendarIcon({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.38997 6.31609C4.26006 7.38323 3.75 9.21706 3.75 12.5C3.75 15.7829 4.26006 17.6168 5.38997 18.6839C6.53405 19.7644 8.50968 20.25 12 20.25C15.4882 20.25 17.4759 19.7657 18.6236 18.7339C19.7445 17.7263 20.25 16.02 20.25 13C20.25 9.45625 19.7366 7.49408 18.5974 6.36635C17.4562 5.23668 15.4921 4.75 12 4.75C8.50968 4.75 6.53405 5.23557 5.38997 6.31609ZM4.36003 5.22557C5.96595 3.70887 8.49032 3.25 12 3.25C15.5079 3.25 18.0438 3.70776 19.6526 5.30032C21.2634 6.89481 21.75 9.43264 21.75 13C21.75 16.0911 21.2555 18.3848 19.6264 19.8494C18.0241 21.2899 15.5118 21.75 12 21.75C8.49032 21.75 5.96595 21.2911 4.36003 19.7744C2.73994 18.2443 2.25 15.8282 2.25 12.5C2.25 9.17183 2.73994 6.75566 4.36003 5.22557Z"
        fill={color || theme?.pallet?.primary[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2.25C15.4142 2.25 15.75 2.58579 15.75 3V6C15.75 6.41421 15.4142 6.75 15 6.75C14.5858 6.75 14.25 6.41421 14.25 6V3C14.25 2.58579 14.5858 2.25 15 2.25Z"
        fill={color || theme?.pallet?.primary[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25C9.41421 2.25 9.75 2.58579 9.75 3V6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6V3C8.25 2.58579 8.58579 2.25 9 2.25Z"
        fill={color || theme?.pallet?.primary[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9.25C7 8.83579 7.33579 8.5 7.75 8.5H16.25C16.6642 8.5 17 8.83579 17 9.25C17 9.66421 16.6642 10 16.25 10H7.75C7.33579 10 7 9.66421 7 9.25Z"
        fill={color || theme?.pallet?.primary[600]}
      />
    </svg>
  );
}

export default CalendarIcon;
