import { SVGprop } from "./types";

function ExchangeShape({ size }: SVGprop) {
  return (
    <svg
      width={size || "75"}
      height={size || "75"}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6819 9.0998C15.0284 11.1074 8.59375 18.8544 8.59375 28.125C8.59375 29.4194 7.54442 30.4688 6.25 30.4688C4.95558 30.4688 3.90625 29.4194 3.90625 28.125C3.90625 14.7368 14.7368 3.90625 28.125 3.90625C28.9694 3.90625 29.7485 4.36045 30.1645 5.09523C30.5806 5.83001 30.5692 6.73178 30.1348 7.45584L26.8535 12.9246C26.1876 14.0345 24.7479 14.3945 23.6379 13.7285C22.528 13.0625 22.1681 11.6229 22.834 10.5129L23.6819 9.0998ZM68.75 44.5312C70.0444 44.5312 71.0938 45.5806 71.0938 46.875C71.0938 60.2632 60.2632 71.0938 46.875 71.0938C46.0306 71.0938 45.2515 70.6396 44.8355 69.9048C44.4194 69.17 44.4308 68.2682 44.8652 67.5442L48.1465 62.0754C48.8124 60.9655 50.2521 60.6055 51.362 61.2715C52.472 61.9375 52.8319 63.3771 52.166 64.4871L51.3181 65.9002C59.9716 63.8926 66.4062 56.1456 66.4062 46.875C66.4062 45.5806 67.4556 44.5312 68.75 44.5312Z"
        fill="#FFD345"
      />
      <path
        d="M51.25 25.6563L39.5 19.3125C38.25 18.6563 36.7812 18.6563 35.5312 19.3125L23.7812 25.6563C22.9375 26.125 22.4063 27.0312 22.4063 28.0625C22.4063 29.0938 22.9375 30 23.7812 30.4688L35.5312 36.8125C36.1562 37.1563 36.8438 37.3125 37.5313 37.3125C38.2188 37.3125 38.9062 37.1563 39.5312 36.8125L51.2813 30.4688C52.125 30 52.6563 29.0938 52.6563 28.0625C52.625 27.0312 52.0937 26.125 51.25 25.6563Z"
        fill="#FFD345"
      />
      <path
        d="M33.5625 38.9687L22.625 33.5C21.7812 33.0937 20.8125 33.125 20 33.625C19.1875 34.125 18.7187 34.9687 18.7187 35.9062V46.25C18.7187 48.0312 19.7188 49.6562 21.3125 50.4375L32.25 55.9062C32.625 56.0937 33.0312 56.1875 33.4687 56.1875C33.9687 56.1875 34.4375 56.0625 34.875 55.7812C35.6875 55.2812 36.1563 54.4375 36.1563 53.5V43.1562C36.1563 41.375 35.1875 39.7812 33.5625 38.9687Z"
        fill="white"
      />
      <path
        d="M54.9687 33.625C54.1562 33.125 53.1875 33.0937 52.3437 33.5L41.4062 38.9687C39.8125 39.7812 38.8125 41.375 38.8125 43.1562V53.5C38.8125 54.4375 39.2812 55.2812 40.0937 55.7812C40.5312 56.0625 41 56.1875 41.5 56.1875C41.9062 56.1875 42.3125 56.0937 42.7188 55.9062L53.6563 50.4375C55.25 49.625 56.25 48.0312 56.25 46.25V35.9062C56.25 34.9687 55.7812 34.125 54.9687 33.625Z"
        fill="white"
      />
    </svg>
  );
}

export default ExchangeShape;
