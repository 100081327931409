export const BANK_ACCOUNT = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const IBAN_MASK = [
  /[A-Za-z]/,
  /[A-Za-z]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9-A-Za-z]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const IBAN_REGX =
  /^([A-Za-z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Z0-9]){9,30}$)((?:[ \\-]?[A-Z0-9]{3,5}){2,7})([ \\-]?[A-Z0-9]{1,3})?$/;

export const PHONE_NUMBER_MASK = [
  /(5|٥)/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
  /([0-9]|[\u0660-\u0669])/,
];
export const NUMBER_MASK = Array.from(
  { length: 128 },
  () => /([0-9]|[\u0660-\u0669])/,
);
export const PHONE_NUMBER_REGEX = /^5(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;

export const TERMS_CONDITIONS_LINK = {
  dev: "https://dev-yamm.yammrefund.com/terms-and-conditions",
  prod: "https://yamm.sa/terms-and-conditions",
};

export const ARABIC_ENGLISH_LETTERS =
  /^([a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF]).{0,30}$/;
