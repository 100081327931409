const keys = {
  checkOrderAuth: "checkOrder-auth-form",
  checkOrderAuthStatus: "checkOrder-auth-status",
  addAddressModal: "add-address",
  addBankAccountModal: "add-bank-account",
  termsModal: "terms-modal",
  nafathVerificationModal: "nafath-verification-modal",
  noCarrierModal: "no-carrier-modal",
};

export default keys;
