import React from "react";
import { LocalizedNumberInput } from "components/Inputs";
import { formDataHandler } from "helpers/functions";
import { useAxiosInstance } from "hooks";
import { Button } from "components/Buttons";
import { Flex } from "components/Grids";
import Form from "components/Form";

type Props = {
  callback: () => void;
};

export default function NationalForm({ callback }: Props) {
  const { post } = useAxiosInstance();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async (data: any) => {
      setSubmitting(true);
      try {
        await post("customer/update-national-id", formDataHandler(data));
        callback();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setSubmitting(false);
      }
    },
    [callback, post],
  );

  return (
    <Form onSubmit={onSubmit}>
      {({ control }) => (
        <Flex gap={{ xs: 6, md: 8, lg: 16 }} direction="column" fullWidth>
          <LocalizedNumberInput
            name="national_id"
            label="nationalId"
            required="nationalIdRequired"
            control={control}
            maxLength={10}
            validationRules={{
              minLength: { value: 10, message: "nationalIdMinLength" },
            }}
          />
          <Button isLoading={submitting} fullWidth>
            updateNationalId
          </Button>
        </Flex>
      )}
    </Form>
  );
}
