import React from "react";
import { Link } from "components/Links";
import { useBreakpoints } from "hooks";
import Logo from "components/Logo";

type Props = {
  color: "white" | "colored";
};

export default function HeaderLogo({ color }: Props) {
  const { large, xxLarge } = useBreakpoints();

  return (
    <Link to="/">
      <Logo color={color} size={xxLarge ? 144 : large ? 120 : 100} />
    </Link>
  );
}
