import { ThemeProvider } from "@emotion/react";
import Snackbar from "components/Snackbar";
import { useAppDispatch, useAppSelector, useAuth, useQuery } from "hooks";
import i18next from "i18next";
import "locales";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import getTheme from "theme";
import FontSetup from "theme/GlobalStyles/FontsSetup";
import GlobalStyles from "theme/GlobalStyles/GlobalStyles";

import NafathRevalidation from "app/modules/auth/NafathRevalidation";
import { QueryClient, QueryClientProvider } from "react-query";
import getBrandingService from "state/ui/getBrandingService";

type Props = {
  children: React.ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function AppProvider({ children }: Props) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("app");
  const query = useQuery();

  const language = useAppSelector((state) => state.ui.language);
  const snackbar = useAppSelector((state) => state.uiActions.snackbar);
  const brandingDetails = useAppSelector((state) => state.ui.brandingDetails);

  const nafathVerificationStatus = useAppSelector(
    (state) => state.consumerAuth.nafathVerificationStatus,
  );

  const { loggedIn } = useAuth();

  React.useEffect(() => {
    i18next.changeLanguage(language);
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(
      getBrandingService({
        config: {
          params: { storeAttribute: query.hostname },
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          theme={{ ...getTheme("light"), branding: brandingDetails }}
        >
          <Helmet
            title={t("titleTab")}
            htmlAttributes={{
              lang: language,
              dir: language === "en" ? "ltr" : "rtl",
            }}
            bodyAttributes={{
              lang: language,
              dir: language === "en" ? "ltr" : "rtl",
            }}
          >
            <meta name="description" content={t("metaDescription")} />
            {process.env.NODE_ENV !== "production" && (
              <>
                <meta name="googlebot" content="noindex" />
                <meta name="robots" content="noindex, nofollow" />
                <meta name="bingbot" content="noindex" />
              </>
            )}
          </Helmet>
          <FontSetup />
          <GlobalStyles />
          {children}
          {snackbar?.message && <Snackbar {...snackbar} />}
          {loggedIn && nafathVerificationStatus === "required" && (
            <NafathRevalidation />
          )}
        </ThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default AppProvider;
