import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB43DIvCwpCHA8BHONckkpVLxN-sjfpodM",
  authDomain: "yamm-core.firebaseapp.com",
  projectId: "yamm-core",
  storageBucket: "yamm-core.appspot.com",
  messagingSenderId: "1049893582865",
  appId: "1:1049893582865:web:ae6c5e81e4160b70bdd9b0",
  measurementId: "G-3D2H8XZ9GM",
};

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
