const URLS = {
  home: "/",
  about: "/about",
  login: "/login",
  verifyNafath: "/verify-nafath",
  register: "/register",
  business: "/business",
  calculator: "/business#calculator",
  notFound: "/404",
  help: "/help",
  suggestions: "/suggestions",
  maintenance: "/maintenance",
  error: "/error",
  loginSuccess: "/login-success",
  loginFailed: "/login-failed",
  account: "/account",
  orders: "/orders",
  createOrder: "/create-order",
  terms: "/terms-and-conditions",
  faqs: "/faqs",
  store: "/store",
  privacy: "/privacy-and-policy",
};

export const externalLinks = {
  termsAndConditions: "https://yamm.sa/terms-and-conditions",
  privacy: "https://yamm.sa/privacy-and-policy",
};

export default URLS;
