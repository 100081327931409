import { RouteType } from "app/routes/types";

const routes: RouteType[] = [
  {
    layout: "empty",
    route: "/error",
    element: import("app/modules/server-error"),
  },
];

export default routes;
