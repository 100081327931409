import { RouteType } from "app/routes/types";

const routes: RouteType[] = [
  {
    route: "/about",
    element: import("app/modules/about"),
  },
];

export default routes;
