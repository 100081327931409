import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function BankIcon({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "28"}
      height={size || "28"}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6654 22.1667V25.6667H2.33203V22.1667C2.33203 21.525 2.85703 21 3.4987 21H24.4987C25.1404 21 25.6654 21.525 25.6654 22.1667Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4987 21.75C3.27124 21.75 3.08203 21.9392 3.08203 22.1667V24.9167H24.9154V22.1667C24.9154 21.9392 24.7262 21.75 24.4987 21.75H3.4987ZM1.58203 22.1667C1.58203 21.1108 2.44282 20.25 3.4987 20.25H24.4987C25.5546 20.25 26.4154 21.1108 26.4154 22.1667V25.6667C26.4154 26.0809 26.0796 26.4167 25.6654 26.4167H2.33203C1.91782 26.4167 1.58203 26.0809 1.58203 25.6667V22.1667Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M8.16536 12.834H5.83203V21.0007H8.16536V12.834Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M12.8333 12.834H10.5V21.0007H12.8333V12.834Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M17.5013 12.834H15.168V21.0007H17.5013V12.834Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M22.1654 12.834H19.832V21.0007H22.1654V12.834Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M26.8346 26.541H1.16797C0.689635 26.541 0.292969 26.1443 0.292969 25.666C0.292969 25.1877 0.689635 24.791 1.16797 24.791H26.8346C27.313 24.791 27.7096 25.1877 27.7096 25.666C27.7096 26.1443 27.313 26.541 26.8346 26.541Z"
        fill={color || theme.pallet.primary[500]}
      />
      <path
        d="M24.9304 6.7075L14.4304 2.5075C14.197 2.41417 13.8004 2.41417 13.567 2.5075L3.06703 6.7075C2.6587 6.87083 2.33203 7.34917 2.33203 7.7925V11.6658C2.33203 12.3075 2.85703 12.8325 3.4987 12.8325H24.4987C25.1404 12.8325 25.6654 12.3075 25.6654 11.6658V7.7925C25.6654 7.34917 25.3387 6.87083 24.9304 6.7075ZM13.9987 9.91583C13.0304 9.91583 12.2487 9.13417 12.2487 8.16583C12.2487 7.1975 13.0304 6.41583 13.9987 6.41583C14.967 6.41583 15.7487 7.1975 15.7487 8.16583C15.7487 9.13417 14.967 9.91583 13.9987 9.91583Z"
        fill={color || theme.pallet.primary[500]}
      />
    </svg>
  );
}

export default BankIcon;
