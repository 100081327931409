import i18n from "locales/i18n";

const en = {
  notRegisteredStore: "This store is not registered",
  goToYamm: "Go to yamm",
  enterRefundDetails: "Enter the details of the order you want to refund",
  applyRefund: "Apply Refund Request",
  poweredBy: "Powered by",
};

i18n.addResourceBundle("en", "app", en);
