import { useTheme } from "@emotion/react";
import { SVGprop } from "./types";

function Logout({ size, color }: SVGprop) {
  const theme = useTheme();

  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90844 7.88857C4.7819 8.91574 4.75 10.2487 4.75 12C4.75 13.7513 4.7819 15.0843 4.90844 16.1114C5.0357 17.1445 5.24943 17.7759 5.54551 18.1859C5.82076 18.567 6.22342 18.8333 6.92246 19.0064C7.64757 19.1859 8.6313 19.25 10 19.25C10.4142 19.25 10.75 19.5858 10.75 20C10.75 20.4142 10.4142 20.75 10 20.75C8.6187 20.75 7.47743 20.6891 6.56192 20.4624C5.62033 20.2292 4.86674 19.808 4.32949 19.0641C3.81307 18.3491 3.55805 17.418 3.41969 16.2948C3.2806 15.1657 3.25 13.7487 3.25 12C3.25 10.2513 3.2806 8.83426 3.41969 7.70518C3.55805 6.58201 3.81307 5.65092 4.32949 4.93588C4.86674 4.19199 5.62032 3.77077 6.56191 3.53761C7.47742 3.31091 8.61869 3.25 9.99999 3.25C10.4142 3.25 10.75 3.58579 10.75 4C10.75 4.41421 10.4142 4.75 9.99999 4.75C8.63129 4.75 7.64757 4.81409 6.92245 4.99364C6.22342 5.16673 5.82076 5.43301 5.54551 5.81412C5.24943 6.22408 5.0357 6.85549 4.90844 7.88857Z"
        fill={color || theme.pallet.primary[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12Z"
        fill={color || theme.pallet.primary[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967L20.5303 11.4697C20.8232 11.7626 20.8232 12.2374 20.5303 12.5303L16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303C15.1768 16.2374 15.1768 15.7626 15.4697 15.4697L18.9393 12L15.4697 8.53033C15.1768 8.23744 15.1768 7.76256 15.4697 7.46967Z"
        fill={color || theme.pallet.primary[600]}
      />
    </svg>
  );
}

export default Logout;
