import { useAuth } from "hooks";
import LoggedUser from "./LoggedUser";
import GuestUser from "./GuestUser";

type Props = {
  transparentMode: boolean;
  scrolledView: boolean;
};

export default function ActionBar({ transparentMode, scrolledView }: Props) {
  const { loggedIn } = useAuth();
  if (loggedIn) return <LoggedUser transparentMode={transparentMode} />;
  return (
    <GuestUser scrolledView={scrolledView} transparentMode={transparentMode} />
  );
}
