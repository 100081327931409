import i18n from "locales/i18n";

i18n.addResourceBundle("ar", "app", {
  learnMore: "مهتم تنضم؟  ",
  weWillContactYou: "حياك، سجل معلوماتك وبنتواصل معاك!",
  storeUrl: " رابط المتجر",
  requestSent: "تم إرسال طلبك",
  joinUsSubmit: "تم",
  applyNow: "انضم اﻵن",
  newAnnualRevenue:
    "المبيعات السنوية الجديدة المتوقعة من عملاء الاسترداد الفوري",
  newMonthlyRevenue:
    "المبيعات الشهرية الجديدة المتوقعة من عملاء الاسترداد الفوري",
  newMonthlyRepurchase:
    "عدد عمليات إعادة الشراء المتوقعة شهريا بعد الاسترداد الفوري",
  averageOrderValue: "معدل قيمة السلة",
  refundRate: "معدل الاسترجاع",
  monthlySalesVolume: "المبيعات الشهرية",
  calculate: "احسب ارباحك الجديدة مع يَمّ !",
  sar: "ريال",
  calculateYourRevenue: "احسب ارباحك الجديدة مع يَمّ !",
  AddNewRevenue: "احسب أرباحك مع يمّ",
});
