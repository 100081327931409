import styled from "@emotion/styled";

const Button = styled.button`
  all: unset;
  padding: 5px;
  border-radius: 25px;
  width: fit-content;
  cursor: pointer;
`;

export default Button;
