import i18n from "locales/i18n";

i18n.addResourceBundle("ar", "app", {
  BackToMyDashboard: "الرجوع إلي صفحتي الرئيسية",
  hi: " هلا ، {{firstName}} {{lastName}}",
  personalInformation: "معلوماتي الشخصية",
  banks: " حساباتي البنكية",
  address: "عناويني",
  update: "تحديث",
  edit: "تحديث",
});
