import i18n from "locales/i18n";

const ar = {
  notRegisteredStore: "هذا المتجر غير مسجل ",
  goToYamm: "اذهب الي يَمّ",
  enterRefundDetails: "ادخل تفاصيل الطلب الذي ترغب في استرداده",
  applyRefund: "تقديم طلب استرداد",
  poweredBy: "هذه الخدمة مدعومة من قبل",
};

i18n.addResourceBundle("ar", "app", ar);
